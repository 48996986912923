import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.location.href = "https://spc-elasticsearch.auth.ap-southeast-1.amazoncognito.com/login?response_type=code&client_id=5o48jlkfuk2rt80n6pbh63ab5i&redirect_uri=https://search-spc-elasticsearch-46ssann3ekgvy3ohoouwdxmvzq.ap-southeast-1.es.amazonaws.com/_plugin/kibana/app/kibana&state=2af78ae2-9aab-4e14-ba19-79a5ddf57b03#/view/e1e1f630-5533-11ef-a813-9baba081e7ce?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1w,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!t,title:'Main%20Dashboard',viewMode:view)";